import { NuxtApp, defineNuxtPlugin } from 'nuxt/app';

/**
 * @see https://github.com/nuxt/nuxt/issues/21743#issuecomment-1605511820
 */
export default defineNuxtPlugin({
  order: -25,
  setup(nuxtApp: NuxtApp) {
    delete nuxtApp.payload.path;
  }
});
