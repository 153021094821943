import type { AxiosInstance } from 'axios';
import AuthService from '~/services/auth-service';
import { COOKIE_AUTHTOKEN_NAME } from '~/constants';

/**
 * @see https://nuxt.com/docs/guide/directory-structure/plugins
 */
export default defineNuxtPlugin(() => {
  const axios = useNuxtApp().$axiosInstance as AxiosInstance;

  return {
    provide: {
      authService: new AuthService(COOKIE_AUTHTOKEN_NAME, axios)
    }
  };
});
