import ENV from '~/config/env';

interface SkillIcon {
  name: string;
  color: string;
}

export default defineNuxtPlugin(() => {
  function techStackIconData(skillId: number) {
    const iconData = techStackIconsMap.get(skillId);

    return iconData
      ? {
          path: ENV.SKILLS_ICON_PACK_URL + iconData.name,
          color: iconData.color
        }
      : { path: '/skill-default.svg', color: '#000' };
  }
  return {
    provide: {
      techStackIconData
    }
  };
});

const techStackIconsMap = new Map<number, SkillIcon>();
techStackIconsMap.set(7, { name: 'angularjs.svg', color: '#E23237' });
techStackIconsMap.set(6, { name: 'angular.svg', color: '#DD0031' });
techStackIconsMap.set(8, { name: 'ansible.svg', color: '#EE0000' });
techStackIconsMap.set(9, { name: 'apachehive.svg', color: '#FDEE21' });
techStackIconsMap.set(10, { name: 'apachekafka.svg', color: '#231F20' });
techStackIconsMap.set(12, { name: 'apachespark.svg', color: '#E25A1C' });
techStackIconsMap.set(17, { name: 'backbonedotjs.svg', color: '#0071B5' });
techStackIconsMap.set(18, { name: 'gnubash.svg', color: '#4EAA25' });
techStackIconsMap.set(21, { name: 'c.svg', color: '#A8B9CC' });
techStackIconsMap.set(22, { name: 'csharp.svg', color: '#239120' });
techStackIconsMap.set(23, { name: 'cplusplus.svg', color: '#00599C' });
techStackIconsMap.set(25, { name: 'apachecassandra.svg', color: '#1287B1' });
techStackIconsMap.set(26, { name: 'chef.svg', color: '#F09820' });
techStackIconsMap.set(28, { name: 'clojure.svg', color: '#5881D8' });
techStackIconsMap.set(29, { name: 'coffeescript.svg', color: '#2F2625' });
techStackIconsMap.set(32, { name: 'dart.svg', color: '#0175C2' });
techStackIconsMap.set(34, { name: 'docker.svg', color: '#2496ED' });
techStackIconsMap.set(38, { name: 'elixir.svg', color: '#4B275F' });
techStackIconsMap.set(39, { name: 'elm.svg', color: '#1293D8' });
techStackIconsMap.set(41, { name: 'emberdotjs.svg', color: '#E04E39' });
techStackIconsMap.set(42, { name: 'erlang.svg', color: '#A90533' });
techStackIconsMap.set(47, { name: 'flask.svg', color: '#000000' });
techStackIconsMap.set(50, { name: 'fortran.svg', color: '#734F96' });
techStackIconsMap.set(52, { name: 'go.svg', color: '#00ADD8' });
techStackIconsMap.set(57, { name: 'graphql.svg', color: '#E10098' });
techStackIconsMap.set(58, { name: 'apachegroovy.svg', color: '#4298B8' });
techStackIconsMap.set(59, { name: 'apachehadoop.svg', color: '#66CCFF' });
techStackIconsMap.set(60, { name: 'haskell.svg', color: '#5D4F85' });
techStackIconsMap.set(73, { name: 'jenkins.svg', color: '#D24939' });
techStackIconsMap.set(75, { name: 'jquery.svg', color: '#0769AD' });
techStackIconsMap.set(72, { name: 'javascript.svg', color: '#F7DF1E' });
techStackIconsMap.set(89, { name: 'meteor.svg', color: '#DE4F4F' });
techStackIconsMap.set(90, { name: 'mongodb.svg', color: '#47A248' });
techStackIconsMap.set(91, { name: 'mysql.svg', color: '#4479A1' });
techStackIconsMap.set(95, { name: 'nodedotjs.svg', color: '#339933' });
techStackIconsMap.set(97, { name: 'ocaml.svg', color: '#EC6813' });
techStackIconsMap.set(100, { name: 'oracle.svg', color: '#F80000' });
techStackIconsMap.set(103, { name: 'perl.svg', color: '#39457E' });
techStackIconsMap.set(111, { name: 'prestashop.svg', color: '#DF0067' });
techStackIconsMap.set(113, { name: 'puppet.svg', color: '#FFAE1A' });
techStackIconsMap.set(114, { name: 'python.svg', color: '#3776AB' });
techStackIconsMap.set(117, { name: 'qt.svg', color: '#41CD52' });
techStackIconsMap.set(118, { name: 'r.svg', color: '#276DC3' });
techStackIconsMap.set(119, { name: 'rabbitmq.svg', color: '#FF6600' });
techStackIconsMap.set(120, { name: 'react.svg', color: '#61DAFB' });
techStackIconsMap.set(121, { name: 'react.svg', color: '#61DAFB' });
techStackIconsMap.set(122, { name: 'redis.svg', color: '#DC383D' });
techStackIconsMap.set(123, { name: 'redux.svg', color: '#764ABC' });
techStackIconsMap.set(125, { name: 'ruby.svg', color: '#CC342D' });
techStackIconsMap.set(126, { name: 'rubyonrails.svg', color: '#CC0000' });
techStackIconsMap.set(127, { name: 'rust.svg', color: '#000000' });
techStackIconsMap.set(129, { name: 'sass.svg', color: '#CC6699' });
techStackIconsMap.set(130, { name: 'scala.svg', color: '#DC322F' });
techStackIconsMap.set(133, { name: 'selenium.svg', color: '#43B02A' });
techStackIconsMap.set(136, { name: 'rubysinatra.svg', color: '#000000' });
techStackIconsMap.set(137, { name: 'apachesolr.svg', color: '#D9411E' });
techStackIconsMap.set(142, {
  name: 'microsoftsqlserver.svg',
  color: '#CC2927'
});
techStackIconsMap.set(146, { name: 'swift.svg', color: '#F05138' });
techStackIconsMap.set(148, { name: 'tableau.svg', color: '#E97627' });
techStackIconsMap.set(155, { name: 'typescript.svg', color: '#3178C6' });
techStackIconsMap.set(159, { name: 'dotnet.svg', color: '#512BD4' });
techStackIconsMap.set(163, { name: 'vuedotjs.svg', color: '#4FC08D' });
