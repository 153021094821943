export default defineNuxtPlugin(() => {
  function keyValuesIconData(keyId: number) {
    const iconData = keyValuesIconsMap.get(keyId);

    return iconData
      ? {
          path: '/key-values/' + iconData
        }
      : { path: '/keyvalues-default.svg' };
  }
  return {
    provide: {
      keyValuesIconData
    }
  };
});

const keyValuesIconsMap = new Map<number, string>();
keyValuesIconsMap.set(1, 'transparency.svg');
keyValuesIconsMap.set(2, 'consistency.svg');
keyValuesIconsMap.set(3, 'trust.svg');
keyValuesIconsMap.set(4, 'respect.svg');
keyValuesIconsMap.set(5, 'ethics.svg');
keyValuesIconsMap.set(6, 'continuous-improvement.svg');
keyValuesIconsMap.set(7, 'team-empowerment.svg');
keyValuesIconsMap.set(8, 'people_s-well-being.svg');
keyValuesIconsMap.set(9, 'quality-and-results.svg');
keyValuesIconsMap.set(10, 'continuous-learning.svg');
keyValuesIconsMap.set(11, 'diversity-and-inclusion.svg');
