export const ADD_NEW_MATCH_PAGE = 'candidates-page-add-new-match';
export const EDIT_MATCH_PAGE = 'candidates-page-edit-match';
export const EDIT_JOB_PAGE = 'job-positions-page-edit-job';
export const ADD_NEW_JOB_PAGE = 'job-positions-page-add-new-job';
export const EDIT_CANDIDATE_PAGE = 'candidates-page-edit';
export const EDIT_COMPANY_PAGE = 'companies-page-edit';
export const EDIT_USER_COMPANY_PAGE = 'companies-user-edit';
export const ADD_NEW_USER_COMPANY_PAGE = 'companies-page-add-new-user';
export const SEND_ACTIVATION_EMAIL_COMPANY_PAGE = 'companies-page-send-activation-email'
export const ADD_NEW_MATCH_PAGE_FROM_MATCHES = 'matches-page-add-new-match';
