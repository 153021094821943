import { default as applications2NdQ0B7H3wMeta } from "/opt/atlassian/pipelines/agent/build/pages/candidates/[idSlug]/applications.vue?macro=true";
import { default as detailsnyQNtZ6CNlMeta } from "/opt/atlassian/pipelines/agent/build/pages/candidates/[idSlug]/details.vue?macro=true";
import { default as historyYEiiopVFpiMeta } from "/opt/atlassian/pipelines/agent/build/pages/candidates/[idSlug]/history.vue?macro=true";
import { default as logwJbLYmqwmTMeta } from "/opt/atlassian/pipelines/agent/build/pages/candidates/[idSlug]/log.vue?macro=true";
import { default as matchesQ4ZSi9sOujMeta } from "/opt/atlassian/pipelines/agent/build/pages/candidates/[idSlug]/matches.vue?macro=true";
import { default as openSelectionsyNfaGqnkctMeta } from "/opt/atlassian/pipelines/agent/build/pages/candidates/[idSlug]/openSelections.vue?macro=true";
import { default as statistics4Dxbl6KqRKMeta } from "/opt/atlassian/pipelines/agent/build/pages/candidates/[idSlug]/statistics.vue?macro=true";
import { default as _91idSlug_93DJ7g2f8WglMeta } from "/opt/atlassian/pipelines/agent/build/pages/candidates/[idSlug].vue?macro=true";
import { default as add_45new_45matchx2hewg1fNsMeta } from "/opt/atlassian/pipelines/agent/build/pages/candidates/add-new-match.vue?macro=true";
import { default as edit_45candidateOmj6TbahEuMeta } from "/opt/atlassian/pipelines/agent/build/pages/candidates/edit-candidate.vue?macro=true";
import { default as edit_45matchK7XB1a0i9kMeta } from "/opt/atlassian/pipelines/agent/build/pages/candidates/edit-match.vue?macro=true";
import { default as indexe5TXIUwNuBMeta } from "/opt/atlassian/pipelines/agent/build/pages/candidates/index.vue?macro=true";
import { default as applicationsKHslOsLtfxMeta } from "/opt/atlassian/pipelines/agent/build/pages/companies/[companyId]/applications.vue?macro=true";
import { default as detailsms5lbo1eGTMeta } from "/opt/atlassian/pipelines/agent/build/pages/companies/[companyId]/details.vue?macro=true";
import { default as historyxvNJkBTVhpMeta } from "/opt/atlassian/pipelines/agent/build/pages/companies/[companyId]/history.vue?macro=true";
import { default as jobPositions98gvcCAxOEMeta } from "/opt/atlassian/pipelines/agent/build/pages/companies/[companyId]/jobPositions.vue?macro=true";
import { default as matchesIDcsvE8WdyMeta } from "/opt/atlassian/pipelines/agent/build/pages/companies/[companyId]/matches.vue?macro=true";
import { default as openSelectionsqfM1VMlbbzMeta } from "/opt/atlassian/pipelines/agent/build/pages/companies/[companyId]/openSelections.vue?macro=true";
import { default as usersLk2CODgcGBMeta } from "/opt/atlassian/pipelines/agent/build/pages/companies/[companyId]/users.vue?macro=true";
import { default as _91companyId_93htlvdSvqnIMeta } from "/opt/atlassian/pipelines/agent/build/pages/companies/[companyId].vue?macro=true";
import { default as add_45new_45userSxlcdeEi3bMeta } from "/opt/atlassian/pipelines/agent/build/pages/companies/add-new-user.vue?macro=true";
import { default as edit_45userLnBYOttEmTMeta } from "/opt/atlassian/pipelines/agent/build/pages/companies/edit-user.vue?macro=true";
import { default as index2AA4erLXXNMeta } from "/opt/atlassian/pipelines/agent/build/pages/companies/index.vue?macro=true";
import { default as send_45activation_45email13f73uWwrgMeta } from "/opt/atlassian/pipelines/agent/build/pages/companies/send-activation-email.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as applicationsO6JuoWBJgxMeta } from "/opt/atlassian/pipelines/agent/build/pages/job-positions/[jobId]/applications.vue?macro=true";
import { default as detailsTo36EzPUVGMeta } from "/opt/atlassian/pipelines/agent/build/pages/job-positions/[jobId]/details.vue?macro=true";
import { default as historyDkzy7CQnyyMeta } from "/opt/atlassian/pipelines/agent/build/pages/job-positions/[jobId]/history.vue?macro=true";
import { default as matches9o1f4EykkmMeta } from "/opt/atlassian/pipelines/agent/build/pages/job-positions/[jobId]/matches.vue?macro=true";
import { default as openSelectionskYoqrFKVMyMeta } from "/opt/atlassian/pipelines/agent/build/pages/job-positions/[jobId]/openSelections.vue?macro=true";
import { default as statistics4Wqga6TwtJMeta } from "/opt/atlassian/pipelines/agent/build/pages/job-positions/[jobId]/statistics.vue?macro=true";
import { default as _91jobId_93B90qHBY1QZMeta } from "/opt/atlassian/pipelines/agent/build/pages/job-positions/[jobId].vue?macro=true";
import { default as add_45new_45jobJRWY6FqP81Meta } from "/opt/atlassian/pipelines/agent/build/pages/job-positions/add-new-job.vue?macro=true";
import { default as edit_45jobXF4MoB9ejSMeta } from "/opt/atlassian/pipelines/agent/build/pages/job-positions/edit-job.vue?macro=true";
import { default as indexW3cxMIkdeAMeta } from "/opt/atlassian/pipelines/agent/build/pages/job-positions/index.vue?macro=true";
import { default as add_45new_45matchcHRLnTJIitMeta } from "/opt/atlassian/pipelines/agent/build/pages/matches/add-new-match.vue?macro=true";
import { default as applicationsS2HZBf88wbMeta } from "/opt/atlassian/pipelines/agent/build/pages/matches/applications.vue?macro=true";
import { default as historyRDO2vuBvEOMeta } from "/opt/atlassian/pipelines/agent/build/pages/matches/history.vue?macro=true";
import { default as indexpeBwGGM8jIMeta } from "/opt/atlassian/pipelines/agent/build/pages/matches/index.vue?macro=true";
import { default as matchesVZFeGsfsltMeta } from "/opt/atlassian/pipelines/agent/build/pages/matches/matches.vue?macro=true";
import { default as openSelectionsdvdvhC7klBMeta } from "/opt/atlassian/pipelines/agent/build/pages/matches/openSelections.vue?macro=true";
export default [
  {
    name: _91idSlug_93DJ7g2f8WglMeta?.name ?? "candidates-idSlug",
    path: _91idSlug_93DJ7g2f8WglMeta?.path ?? "/candidates/:idSlug()",
    children: [
  {
    name: applications2NdQ0B7H3wMeta?.name ?? "candidates-idSlug-applications",
    path: applications2NdQ0B7H3wMeta?.path ?? "applications",
    meta: applications2NdQ0B7H3wMeta || {},
    alias: applications2NdQ0B7H3wMeta?.alias || [],
    redirect: applications2NdQ0B7H3wMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/candidates/[idSlug]/applications.vue").then(m => m.default || m)
  },
  {
    name: detailsnyQNtZ6CNlMeta?.name ?? "candidates-idSlug-details",
    path: detailsnyQNtZ6CNlMeta?.path ?? "details",
    meta: detailsnyQNtZ6CNlMeta || {},
    alias: detailsnyQNtZ6CNlMeta?.alias || [],
    redirect: detailsnyQNtZ6CNlMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/candidates/[idSlug]/details.vue").then(m => m.default || m)
  },
  {
    name: historyYEiiopVFpiMeta?.name ?? "candidates-idSlug-history",
    path: historyYEiiopVFpiMeta?.path ?? "history",
    meta: historyYEiiopVFpiMeta || {},
    alias: historyYEiiopVFpiMeta?.alias || [],
    redirect: historyYEiiopVFpiMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/candidates/[idSlug]/history.vue").then(m => m.default || m)
  },
  {
    name: logwJbLYmqwmTMeta?.name ?? "candidates-idSlug-log",
    path: logwJbLYmqwmTMeta?.path ?? "log",
    meta: logwJbLYmqwmTMeta || {},
    alias: logwJbLYmqwmTMeta?.alias || [],
    redirect: logwJbLYmqwmTMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/candidates/[idSlug]/log.vue").then(m => m.default || m)
  },
  {
    name: matchesQ4ZSi9sOujMeta?.name ?? "candidates-idSlug-matches",
    path: matchesQ4ZSi9sOujMeta?.path ?? "matches",
    meta: matchesQ4ZSi9sOujMeta || {},
    alias: matchesQ4ZSi9sOujMeta?.alias || [],
    redirect: matchesQ4ZSi9sOujMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/candidates/[idSlug]/matches.vue").then(m => m.default || m)
  },
  {
    name: openSelectionsyNfaGqnkctMeta?.name ?? "candidates-idSlug-openSelections",
    path: openSelectionsyNfaGqnkctMeta?.path ?? "openSelections",
    meta: openSelectionsyNfaGqnkctMeta || {},
    alias: openSelectionsyNfaGqnkctMeta?.alias || [],
    redirect: openSelectionsyNfaGqnkctMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/candidates/[idSlug]/openSelections.vue").then(m => m.default || m)
  },
  {
    name: statistics4Dxbl6KqRKMeta?.name ?? "candidates-idSlug-statistics",
    path: statistics4Dxbl6KqRKMeta?.path ?? "statistics",
    meta: statistics4Dxbl6KqRKMeta || {},
    alias: statistics4Dxbl6KqRKMeta?.alias || [],
    redirect: statistics4Dxbl6KqRKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/candidates/[idSlug]/statistics.vue").then(m => m.default || m)
  }
],
    meta: _91idSlug_93DJ7g2f8WglMeta || {},
    alias: _91idSlug_93DJ7g2f8WglMeta?.alias || [],
    redirect: _91idSlug_93DJ7g2f8WglMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/candidates/[idSlug].vue").then(m => m.default || m)
  },
  {
    name: add_45new_45matchx2hewg1fNsMeta?.name ?? "candidates-add-new-match",
    path: add_45new_45matchx2hewg1fNsMeta?.path ?? "/candidates/add-new-match",
    meta: add_45new_45matchx2hewg1fNsMeta || {},
    alias: add_45new_45matchx2hewg1fNsMeta?.alias || [],
    redirect: add_45new_45matchx2hewg1fNsMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/candidates/add-new-match.vue").then(m => m.default || m)
  },
  {
    name: edit_45candidateOmj6TbahEuMeta?.name ?? "candidates-edit-candidate",
    path: edit_45candidateOmj6TbahEuMeta?.path ?? "/candidates/edit-candidate",
    meta: edit_45candidateOmj6TbahEuMeta || {},
    alias: edit_45candidateOmj6TbahEuMeta?.alias || [],
    redirect: edit_45candidateOmj6TbahEuMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/candidates/edit-candidate.vue").then(m => m.default || m)
  },
  {
    name: edit_45matchK7XB1a0i9kMeta?.name ?? "candidates-edit-match",
    path: edit_45matchK7XB1a0i9kMeta?.path ?? "/candidates/edit-match",
    meta: edit_45matchK7XB1a0i9kMeta || {},
    alias: edit_45matchK7XB1a0i9kMeta?.alias || [],
    redirect: edit_45matchK7XB1a0i9kMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/candidates/edit-match.vue").then(m => m.default || m)
  },
  {
    name: indexe5TXIUwNuBMeta?.name ?? "candidates",
    path: indexe5TXIUwNuBMeta?.path ?? "/candidates",
    meta: indexe5TXIUwNuBMeta || {},
    alias: indexe5TXIUwNuBMeta?.alias || [],
    redirect: indexe5TXIUwNuBMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/candidates/index.vue").then(m => m.default || m)
  },
  {
    name: _91companyId_93htlvdSvqnIMeta?.name ?? "companies-companyId",
    path: _91companyId_93htlvdSvqnIMeta?.path ?? "/companies/:companyId()",
    children: [
  {
    name: applicationsKHslOsLtfxMeta?.name ?? "companies-companyId-applications",
    path: applicationsKHslOsLtfxMeta?.path ?? "applications",
    meta: applicationsKHslOsLtfxMeta || {},
    alias: applicationsKHslOsLtfxMeta?.alias || [],
    redirect: applicationsKHslOsLtfxMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/companies/[companyId]/applications.vue").then(m => m.default || m)
  },
  {
    name: detailsms5lbo1eGTMeta?.name ?? "companies-companyId-details",
    path: detailsms5lbo1eGTMeta?.path ?? "details",
    meta: detailsms5lbo1eGTMeta || {},
    alias: detailsms5lbo1eGTMeta?.alias || [],
    redirect: detailsms5lbo1eGTMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/companies/[companyId]/details.vue").then(m => m.default || m)
  },
  {
    name: historyxvNJkBTVhpMeta?.name ?? "companies-companyId-history",
    path: historyxvNJkBTVhpMeta?.path ?? "history",
    meta: historyxvNJkBTVhpMeta || {},
    alias: historyxvNJkBTVhpMeta?.alias || [],
    redirect: historyxvNJkBTVhpMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/companies/[companyId]/history.vue").then(m => m.default || m)
  },
  {
    name: jobPositions98gvcCAxOEMeta?.name ?? "companies-companyId-jobPositions",
    path: jobPositions98gvcCAxOEMeta?.path ?? "jobPositions",
    meta: jobPositions98gvcCAxOEMeta || {},
    alias: jobPositions98gvcCAxOEMeta?.alias || [],
    redirect: jobPositions98gvcCAxOEMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/companies/[companyId]/jobPositions.vue").then(m => m.default || m)
  },
  {
    name: matchesIDcsvE8WdyMeta?.name ?? "companies-companyId-matches",
    path: matchesIDcsvE8WdyMeta?.path ?? "matches",
    meta: matchesIDcsvE8WdyMeta || {},
    alias: matchesIDcsvE8WdyMeta?.alias || [],
    redirect: matchesIDcsvE8WdyMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/companies/[companyId]/matches.vue").then(m => m.default || m)
  },
  {
    name: openSelectionsqfM1VMlbbzMeta?.name ?? "companies-companyId-openSelections",
    path: openSelectionsqfM1VMlbbzMeta?.path ?? "openSelections",
    meta: openSelectionsqfM1VMlbbzMeta || {},
    alias: openSelectionsqfM1VMlbbzMeta?.alias || [],
    redirect: openSelectionsqfM1VMlbbzMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/companies/[companyId]/openSelections.vue").then(m => m.default || m)
  },
  {
    name: usersLk2CODgcGBMeta?.name ?? "companies-companyId-users",
    path: usersLk2CODgcGBMeta?.path ?? "users",
    meta: usersLk2CODgcGBMeta || {},
    alias: usersLk2CODgcGBMeta?.alias || [],
    redirect: usersLk2CODgcGBMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/companies/[companyId]/users.vue").then(m => m.default || m)
  }
],
    meta: _91companyId_93htlvdSvqnIMeta || {},
    alias: _91companyId_93htlvdSvqnIMeta?.alias || [],
    redirect: _91companyId_93htlvdSvqnIMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/companies/[companyId].vue").then(m => m.default || m)
  },
  {
    name: add_45new_45userSxlcdeEi3bMeta?.name ?? "companies-add-new-user",
    path: add_45new_45userSxlcdeEi3bMeta?.path ?? "/companies/add-new-user",
    meta: add_45new_45userSxlcdeEi3bMeta || {},
    alias: add_45new_45userSxlcdeEi3bMeta?.alias || [],
    redirect: add_45new_45userSxlcdeEi3bMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/companies/add-new-user.vue").then(m => m.default || m)
  },
  {
    name: edit_45userLnBYOttEmTMeta?.name ?? "companies-edit-user",
    path: edit_45userLnBYOttEmTMeta?.path ?? "/companies/edit-user",
    meta: edit_45userLnBYOttEmTMeta || {},
    alias: edit_45userLnBYOttEmTMeta?.alias || [],
    redirect: edit_45userLnBYOttEmTMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/companies/edit-user.vue").then(m => m.default || m)
  },
  {
    name: index2AA4erLXXNMeta?.name ?? "companies",
    path: index2AA4erLXXNMeta?.path ?? "/companies",
    meta: index2AA4erLXXNMeta || {},
    alias: index2AA4erLXXNMeta?.alias || [],
    redirect: index2AA4erLXXNMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/companies/index.vue").then(m => m.default || m)
  },
  {
    name: send_45activation_45email13f73uWwrgMeta?.name ?? "companies-send-activation-email",
    path: send_45activation_45email13f73uWwrgMeta?.path ?? "/companies/send-activation-email",
    meta: send_45activation_45email13f73uWwrgMeta || {},
    alias: send_45activation_45email13f73uWwrgMeta?.alias || [],
    redirect: send_45activation_45email13f73uWwrgMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/companies/send-activation-email.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index",
    path: indexOD9t3F2bSJMeta?.path ?? "/",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91jobId_93B90qHBY1QZMeta?.name ?? "job-positions-jobId",
    path: _91jobId_93B90qHBY1QZMeta?.path ?? "/job-positions/:jobId()",
    children: [
  {
    name: applicationsO6JuoWBJgxMeta?.name ?? "job-positions-jobId-applications",
    path: applicationsO6JuoWBJgxMeta?.path ?? "applications",
    meta: applicationsO6JuoWBJgxMeta || {},
    alias: applicationsO6JuoWBJgxMeta?.alias || [],
    redirect: applicationsO6JuoWBJgxMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/job-positions/[jobId]/applications.vue").then(m => m.default || m)
  },
  {
    name: detailsTo36EzPUVGMeta?.name ?? "job-positions-jobId-details",
    path: detailsTo36EzPUVGMeta?.path ?? "details",
    meta: detailsTo36EzPUVGMeta || {},
    alias: detailsTo36EzPUVGMeta?.alias || [],
    redirect: detailsTo36EzPUVGMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/job-positions/[jobId]/details.vue").then(m => m.default || m)
  },
  {
    name: historyDkzy7CQnyyMeta?.name ?? "job-positions-jobId-history",
    path: historyDkzy7CQnyyMeta?.path ?? "history",
    meta: historyDkzy7CQnyyMeta || {},
    alias: historyDkzy7CQnyyMeta?.alias || [],
    redirect: historyDkzy7CQnyyMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/job-positions/[jobId]/history.vue").then(m => m.default || m)
  },
  {
    name: matches9o1f4EykkmMeta?.name ?? "job-positions-jobId-matches",
    path: matches9o1f4EykkmMeta?.path ?? "matches",
    meta: matches9o1f4EykkmMeta || {},
    alias: matches9o1f4EykkmMeta?.alias || [],
    redirect: matches9o1f4EykkmMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/job-positions/[jobId]/matches.vue").then(m => m.default || m)
  },
  {
    name: openSelectionskYoqrFKVMyMeta?.name ?? "job-positions-jobId-openSelections",
    path: openSelectionskYoqrFKVMyMeta?.path ?? "openSelections",
    meta: openSelectionskYoqrFKVMyMeta || {},
    alias: openSelectionskYoqrFKVMyMeta?.alias || [],
    redirect: openSelectionskYoqrFKVMyMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/job-positions/[jobId]/openSelections.vue").then(m => m.default || m)
  },
  {
    name: statistics4Wqga6TwtJMeta?.name ?? "job-positions-jobId-statistics",
    path: statistics4Wqga6TwtJMeta?.path ?? "statistics",
    meta: statistics4Wqga6TwtJMeta || {},
    alias: statistics4Wqga6TwtJMeta?.alias || [],
    redirect: statistics4Wqga6TwtJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/job-positions/[jobId]/statistics.vue").then(m => m.default || m)
  }
],
    meta: _91jobId_93B90qHBY1QZMeta || {},
    alias: _91jobId_93B90qHBY1QZMeta?.alias || [],
    redirect: _91jobId_93B90qHBY1QZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/job-positions/[jobId].vue").then(m => m.default || m)
  },
  {
    name: add_45new_45jobJRWY6FqP81Meta?.name ?? "job-positions-add-new-job",
    path: add_45new_45jobJRWY6FqP81Meta?.path ?? "/job-positions/add-new-job",
    meta: add_45new_45jobJRWY6FqP81Meta || {},
    alias: add_45new_45jobJRWY6FqP81Meta?.alias || [],
    redirect: add_45new_45jobJRWY6FqP81Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/job-positions/add-new-job.vue").then(m => m.default || m)
  },
  {
    name: edit_45jobXF4MoB9ejSMeta?.name ?? "job-positions-edit-job",
    path: edit_45jobXF4MoB9ejSMeta?.path ?? "/job-positions/edit-job",
    meta: edit_45jobXF4MoB9ejSMeta || {},
    alias: edit_45jobXF4MoB9ejSMeta?.alias || [],
    redirect: edit_45jobXF4MoB9ejSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/job-positions/edit-job.vue").then(m => m.default || m)
  },
  {
    name: indexW3cxMIkdeAMeta?.name ?? "job-positions",
    path: indexW3cxMIkdeAMeta?.path ?? "/job-positions",
    meta: indexW3cxMIkdeAMeta || {},
    alias: indexW3cxMIkdeAMeta?.alias || [],
    redirect: indexW3cxMIkdeAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/job-positions/index.vue").then(m => m.default || m)
  },
  {
    name: add_45new_45matchcHRLnTJIitMeta?.name ?? "matches-add-new-match",
    path: add_45new_45matchcHRLnTJIitMeta?.path ?? "/matches/add-new-match",
    meta: add_45new_45matchcHRLnTJIitMeta || {},
    alias: add_45new_45matchcHRLnTJIitMeta?.alias || [],
    redirect: add_45new_45matchcHRLnTJIitMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/matches/add-new-match.vue").then(m => m.default || m)
  },
  {
    name: applicationsS2HZBf88wbMeta?.name ?? "matches-applications",
    path: applicationsS2HZBf88wbMeta?.path ?? "/matches/applications",
    meta: applicationsS2HZBf88wbMeta || {},
    alias: applicationsS2HZBf88wbMeta?.alias || [],
    redirect: applicationsS2HZBf88wbMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/matches/applications.vue").then(m => m.default || m)
  },
  {
    name: historyRDO2vuBvEOMeta?.name ?? "matches-history",
    path: historyRDO2vuBvEOMeta?.path ?? "/matches/history",
    meta: historyRDO2vuBvEOMeta || {},
    alias: historyRDO2vuBvEOMeta?.alias || [],
    redirect: historyRDO2vuBvEOMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/matches/history.vue").then(m => m.default || m)
  },
  {
    name: indexpeBwGGM8jIMeta?.name ?? "matches",
    path: indexpeBwGGM8jIMeta?.path ?? "/matches",
    meta: indexpeBwGGM8jIMeta || {},
    alias: indexpeBwGGM8jIMeta?.alias || [],
    redirect: indexpeBwGGM8jIMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/matches/index.vue").then(m => m.default || m)
  },
  {
    name: matchesVZFeGsfsltMeta?.name ?? "matches-matches",
    path: matchesVZFeGsfsltMeta?.path ?? "/matches/matches",
    meta: matchesVZFeGsfsltMeta || {},
    alias: matchesVZFeGsfsltMeta?.alias || [],
    redirect: matchesVZFeGsfsltMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/matches/matches.vue").then(m => m.default || m)
  },
  {
    name: openSelectionsdvdvhC7klBMeta?.name ?? "matches-openSelections",
    path: openSelectionsdvdvhC7klBMeta?.path ?? "/matches/openSelections",
    meta: openSelectionsdvdvhC7klBMeta || {},
    alias: openSelectionsdvdvhC7klBMeta?.alias || [],
    redirect: openSelectionsdvdvhC7klBMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/matches/openSelections.vue").then(m => m.default || m)
  }
]