import { CookieOptions } from 'nuxt/app';

/**
 * @name useSyncedCookie
 * @see https://github.com/nuxt/nuxt/issues/21709#issuecomment-1610706501
 * @param name
 * @param options
 */
export function useSyncedCookie(name: string, options?: CookieOptions) {
  return customRef<string | null>((track, trigger) => {
    const expires = options?.expires ? 'expires=' + options.expires + ';' : '';
    const maxAge = options?.maxAge ? 'max-age=' + options.maxAge + ';' : '';
    const domain = options?.domain ? 'domain=' + options.domain + ';' : '';

    return {
      get: () => {
        track();

        return (
          document.cookie
            .split('; ')
            .find((row) => row.startsWith(name))
            ?.split('=')[1] || null
        );
      },
      set: (value: string | null) => {
        document.cookie = `${name}=${value}; path=/; ${expires} ${maxAge} ${domain}`;

        trigger();
      }
    };
  });
}
