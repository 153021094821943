import axios from 'axios';
import type { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import ENV from '~/config/env';

const axiosApiInstance = axios.create({
  baseURL: ENV.API_URL + '/',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    Accept: 'application/json',
    proxyHeaders: true,
    credentials: false
  }
} as AxiosRequestConfig);

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  (config) => {
    const authService = useNuxtApp().$authService;

    if (authService && authService?.isTokenValid()) {
      const authState = authService.getAuthState();
      const beToken = authState?.beToken || '';

      config.headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + beToken
      } as AxiosRequestHeaders;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default axiosApiInstance;
