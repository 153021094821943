import ENV from '~/config/env';

/**
 * @see SSO_URL/#/SignIn?hop=BASE_URL
 * Cookie was set for *stag001.codemotion.com (the third level domain), please check this for local testing.
 */
export default defineNuxtRouteMiddleware(async ({ fullPath }) => {
  const $authService = useNuxtApp().$authService;
  const hop = ENV.BASE_URL + fullPath;

  if (!$authService?.isTokenValid()) {
    /**
     * I don't know why, but this is the only way to redirect to SSO:
     * Use a ash tag in the URL!
     */
    await navigateTo(ENV.SSO_URL + '/#/SignIn?hop=' + encodeURIComponent(hop), {
      external: true,
      replace: true,
      redirectCode: 401
    });

    return abortNavigation(
      createError({
        statusCode: 401,
        statusMessage: 'Unauthorized'
      })
    );
  }
});
