import type { AxiosInstance } from 'axios';
import { useSyncedCookie } from '~/hooks/useCookie';

interface AppAuthState {
  beToken: string;
}

export default class AuthService {
  private currentAuthState: AppAuthState | null = null;
  private readonly localStorageKey: string;

  /**
   * TODO: Implement token renewal
   * @private
   */
  private lastTokenRenew = 0;

  constructor(
    localStorageKey: string,
    private readonly apiClient: AxiosInstance,
    /**
     * TODO: Implement token renewal
     */
    private readonly tokenRenewInterval?: number
  ) {
    this.localStorageKey = localStorageKey;
    this.apiClient = apiClient;

    const token = this.readToken();

    if (token) {
      this.currentAuthState = { beToken: token };

      /**
       * @name invalidateTheState
       * @description This check is useful when will have a token renewal
       */
      if (!this.isTokenValid()) {
        this.currentAuthState = null;
        this.clear();
      }
    }

    this.ensureAuthState();
  }

  private ensureAuthState(): void {
    if (this.currentAuthState && this.isTokenValid()) {
      return;
    }

    /**
     * Set token to Axios instance
     */
    this.setAuthToken();

    /**
     * If Token is expired
     * TODO: renewal flow
     */
  }

  /**
   * @name readToken
   * @description Read the token from cookie or from local storage
   * @private
   */
  private readToken(): string | null {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 15);

    const authCookie = useSyncedCookie(this.localStorageKey, {
      domain:
        import.meta.env.VITE_APP_ENV === 'stage'
          ? 'stag001.codemotion.com'
          : '.codemotion.com',
      expires: currentDate,
      maxAge: 60 * 60 * 24 * 15
    });

    /**
     * This is a fallback for the case when the cookie is not set, I'm not sure if this is needed
     */
    if (!authCookie.value && localStorage.getItem(this.localStorageKey)) {
      return localStorage.getItem(this.localStorageKey) as string;
    }

    /**
     * TODO:
     * Remove this condition after deployment with the correct pipeline
     */
    if (import.meta.env.VITE_APP_ENV === 'local') {
      authCookie.value = import.meta.env.VITE_API_AUTH_TOKEN as string;
    }

    return authCookie.value;
  }

  /**
   * TODO: Implement this method with token renewal
   * @private
   */
  public isTokenValid(): boolean {
    return !!this.currentAuthState?.beToken;
  }

  private clear(): void {
    const authCookie = useSyncedCookie(this.localStorageKey, {
      domain:
        import.meta.env.VITE_APP_ENV === 'stage'
          ? 'stag001s.codemotion.com'
          : '.codemotion.com',
      maxAge: -1
    });

    authCookie.value = null;
    /**
     * Remove other cookies that are not needed
     */
    const authCookieNew = useSyncedCookie('cmtknv', {
      domain:
        import.meta.env.VITE_APP_ENV === 'local'
          ? 'localhost'
          : '.codemotion.com',
      maxAge: -1
    });
    authCookieNew.value = null;

    localStorage.removeItem(this.localStorageKey);
    localStorage.clear();
    location.reload();
  }

  public getAuthState(): AppAuthState | null {
    return this.currentAuthState;
  }

  public logout(): void {
    this.currentAuthState = null;
    this.clear();
  }

  /**
   * @name setAuthToken
   * @description Set the token to axios instance interceptor
   * @private
   */
  private setAuthToken() {
    if (this.currentAuthState?.beToken) {
      this.apiClient.defaults.headers.common.Authorization = `Bearer ${this.currentAuthState?.beToken}}`;
    } else {
      delete this.apiClient.defaults.headers.common.Authorization;
    }
  }
}
