export const APP_NAME = 'cm-talent-partner-bo';
export const APP_NAME_TITLE = 'Codemotion Talent Partner';
export const APP_DESCRIPTION =
  'Dashboard per la gestione dei talenti e delle aziende.';
export const CONSOLE_MESSAGE = '%c Hi 👋 We are Codemotion!';
export const COOKIE_AUTHTOKEN_NAME = 'cmtkn';

export const sessionTTL: number = 60 * 1000 * 10; // 10 minute

/**
 * @name API_URL_REST
 */
export const MATCHES_ADMIN = `/admin/matches/by-status`;
export const GET_TALENT_MANAGER_REST = `/admin/talentmanagers`;
export const GET_CANDIDATES_REST = `/admin/candidates`;
export const GET_CANDIDATE_REST = `/admin/candidate`;
export const GET_MATCHES_BY_CANDIDATE_REST = `/admin/matches/candidate`; // or admin/matches/job
export const GET_MATCHES_BY_COMPANY_REST = `/admin/matches/company`;
export const MATCHES_BY_JOB_REST = `/admin/matches/job`;
export const MATCHES_BY_CANDIDATE_TO_SHORTLIST_REST = `/admin/matches/shortlist`;
export const MATCH_REST = `/admin/match`;
export const GET_JOBS_REST = `/admin/jobs`;
export const GET_JOBS_BY_COMPANY_REST = `/admin/jobs/company`;

export const GET_SKILLS_CATEGORIES_REST = `/talent/skillscategories`;
export const GET_KEYVALUE_INDUSTRY_REST = `/talent/keyvaluesindustry`;
export const GET_CANDIDATE_ITEMS_REST = `/talent/candidateitems`;
export const GET_COMPANY_ITEMS_REST = `/talent/companyitems`;

export const POST_COMPANIES_BY_IDS = `/companies/byids`;
export const GET_COMPANIES = `/admin/companies`;
export const GET_COMPANIES_WITH_COUNTS = `/admin/companies/withCounts`;

export const POST_ACTIVATION_EMAIL_WH = `/wh/talent/sendActivationEmail`;
