/**
 * @see https://vitejs.dev/guide/env-and-mode.html#env-files
 */
const runEnv = import.meta.env.VITE_APP_ENV || 'local';

const ENV = {
  local: {
    API_URL: 'http://localhost:8166/api',
    PROFILE_PAGE_URL: 'https://my.stag001.codemotion.com',
    SSO_URL: 'https://id.stag001.codemotion.com',
    BASE_URL: 'http://localhost:3000',
    SKILLS_ICON_PACK_URL: 'https://cdn.jsdelivr.net/npm/simple-icons@v7/icons/',
    BRAND_PAGES_URL: 'https://partners.codemotion.com/',
    JOB_BOARD_URL: 'https://www.codemotion.com/talent/jobs/',
    EDIT_COMPANY_URL: 'https://bo.stag001.codemotion.com/hiring/companies/',
    ACTIVEHOSTED_URL: 'https://codemotion.activehosted.com/app/',
    OPEN_STREET_MAP_SEARCH: 'https://nominatim.openstreetmap.org/search'
  },
  test: {
    API_URL: 'https://forwork.stag001.codemotion.com/api',
    PROFILE_PAGE_URL: 'https://my.stag001.codemotion.com',
    SSO_URL: 'https://id.stag001.codemotion.com',
    BASE_URL: 'https://calm-flower-0a6450110.3.azurestaticapps.net',
    SKILLS_ICON_PACK_URL: 'https://cdn.jsdelivr.net/npm/simple-icons@v7/icons/',
    BRAND_PAGES_URL: 'https://partners.stag001.codemotion.com/',
    JOB_BOARD_URL: 'https://www.codemotion.com/talent/jobs/',
    EDIT_COMPANY_URL: 'https://bo.stag001.codemotion.com/hiring/companies/',
    ACTIVEHOSTED_URL: 'https://codemotion.activehosted.com/app/',
    OPEN_STREET_MAP_SEARCH: 'https://nominatim.openstreetmap.org/search'
  },
  stage: {
    API_URL: 'https://forwork.stag001.codemotion.com/api',
    PROFILE_PAGE_URL: 'https://my.stag001.codemotion.com',
    SSO_URL: 'https://id.stag001.codemotion.com',
    BASE_URL: 'https://bo-talent.stag001.codemotion.com',
    SKILLS_ICON_PACK_URL: 'https://cdn.jsdelivr.net/npm/simple-icons@v7/icons/',
    BRAND_PAGES_URL: 'https://partners.stag001.codemotion.com/',
    JOB_BOARD_URL: 'https://www.codemotion.com/talent/jobs/',
    EDIT_COMPANY_URL: 'https://bo.stag001.codemotion.com/hiring/companies/',
    ACTIVEHOSTED_URL: 'https://codemotion.activehosted.com/app/',
    OPEN_STREET_MAP_SEARCH: 'https://nominatim.openstreetmap.org/search'
  },
  prod: {
    API_URL: 'https://forwork.codemotion.com/api',
    PROFILE_PAGE_URL: 'https://my.codemotion.com',
    SSO_URL: 'https://id.codemotion.com',
    BASE_URL: 'https://backoffice.codemotion.com',
    SKILLS_ICON_PACK_URL: 'https://cdn.jsdelivr.net/npm/simple-icons@v7/icons/',
    BRAND_PAGES_URL: 'https://partners.codemotion.com/',
    JOB_BOARD_URL: 'https://www.codemotion.com/jobs/',
    EDIT_COMPANY_URL: 'https://bo.codemotion.com/hiring/companies/',
    ACTIVEHOSTED_URL: 'https://codemotion.activehosted.com/app/',
    OPEN_STREET_MAP_SEARCH: 'https://nominatim.openstreetmap.org/search'
  }
};

export default ENV[runEnv];
